import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import care from "../assets/care.png";
import refer from "../assets/refer.png";
import shoppingCart from "../assets/shoppingCart.png";
import lines from "../assets/lines.png";
import MenuModule from "./MenuModule";


function Header(props) {
    const [puffs, setPuffs] = useState(false);

  console.log("props.cartTotal?.Items_Count");
  console.log(props.cartTotal?.Items_Count);

  return (
    <div className="sinFrans flex justify-between px-5 md:px-10 items-center bg-black sticky top-0 z-50">
      {/* <div className="flex justify-between "> */}
        {/* <img
          onClick={() => props.setMenu(true)}
          className="w-14 cursor-pointer mr-5"
          src={lines}
          alt=""
        /> */}
        {/* {props.menu ?
                <MenuModule modale={props.menu} showModale={props.setMenu} >
                <div className="text-sm md:text-base mt-5">
                                <Link to="/"><p className="hover:text-gray-500"><strong>Home</strong></p></Link>
                                <div className="flex">
                                    <p className="cursor-pointer mt-3 hover:text-gray-500" onClick={() => setPuffs(!puffs)}><strong>Puffs</strong></p>
                                    {puffs ?
                                        <div className="mt-2 ml-5 border-2 border-gray-500 p-3 bg-white rounded-lg">
                                            <a href="#700"><p className="hover:text-gray-500 cursor-pointer"><strong>700 PUFF</strong></p></a>
                                            <a href="#1800"><p className="hover:text-gray-500 mt-3 cursor-pointer"><strong>1800 PUFF</strong></p></a>
                                            <a href="#2500"><p className="hover:text-gray-500 mt-3 cursor-pointer"><strong>2500 PUFF</strong></p></a>
                                        </div> :
                                        ''}
                                </div>
                                <Link to="/contact-us"><p className="mt-2 hover:text-gray-500"><strong>Contact Us</strong></p></Link>
                            </div>
                </MenuModule>
                :
                ''
            } */}
        <Link to="/">
          <p className=" logo py-3 text-2xl w-32 cursor-pointer">NM VAPES</p>
        </Link>
      {/* </div> */}
      <input
        className="hidden md:block p-4 border rounded w-7/12 h-10"
        placeholder="What are you looking for?"
        type="text"
      />

      <div className=" mb-5 md:mb-0 flex justify-between space-x-6 md:space-x-12 text-lg">
        {/* <div onClick={() => props.setInModule(true)} className="cursor-pointer flex">
                    <div className="hidden md:block head border-b-4 border-black hover:border-b-3 py-4">
                        Sign In
                    </div>
                    <div className="mt-5">
                        <img className="w-16 ml-2 border-r-2 pr-10" src={refer} alt="" />
                    </div>
                </div> */}
        <Link to="/cart">
          <div className="cursor-pointer flex "><div className="hidden md:block head border-b-4 border-black hover:border-b-3 py-4">
              Cart
            </div>
            <div className="mt-5">
              <img className="w-6 ml-2" src={shoppingCart} alt="" />
            </div>
            {props.cartTotal?.Items_Count !== undefined ? (
              <div>
                <p className="forBackground text-black pt-1 pb-1 pl-3 pr-3 border-1 border-indigo-50 rounded-full ">
                  {props.cartTotal?.Items_Count}
                </p>
              </div>
            ) : (
              <div>
                <p className="bg-black text-black pt-1 pb-1 pl-3 pr-3 border-1 border-indigo-50 rounded-full ">
                  {props.cartTotal?.Items_Count}
                </p>
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
