import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import home from "../assets/home.png";
import ProductCard from "./body/ProductCard";
import { Link } from "react-router-dom";
import CartModule from "./CartModule";
import OrderSummary from "./OrderSummary";
import Module from "./Module";
// import close from "./assets/close.png";
import close from "../assets/close.png";



function ProductById(props) {
    const { id } = useParams();
    const [product, setProduct] = useState();
    const [modale, showModale] = useState(false);
    const [object, setObject] = useState([]);
    const [email, setEmail] = useState();
    const [pass, setPass] = useState();
    const [first, setFirst] = useState();
    const [last, setLast] = useState(1);
    const [em, setem] = useState();
    const [password, setPassword] = useState();
    const [popMessage, setPopMessage] = useState();

    const [response, setResponse] = useState();
    // const [quantity, setQuantity] = useState(2);

    const openSignUp = () => {
        setInModule(false);
        setUpModule(true);
    }
    const openSignIn = () => {
        setInModule(true);
        setUpModule(false);
    }
    const closeModule = () => {
        setInModule(false);
        setUpModule(false);
    }
    const [inModule, setInModule] = useState(false);
    const [upModule, setUpModule] = useState(false);


    console.log("ID")
    console.log(id)
    console.log(product)

    const getProductById = () => {
        axios.get(`https://nmvapes.com/eCommerce/api/products_read_single.php?product_id=${id}`,
            {
                view: 'web_desktop'
            }, {
            headers: {
                // 'Authorization': data
            }
        })
            .then((res) => {
                setProduct(res.data)

            })
            .catch((error) => {
                console.error(error)
            });
    }
    async function signIn() {
        console.log("clicked");
        axios.post(`https://nmvapes.com/eCommerce/api/user_login.php`,
            {

                email: email,
                password: pass

            }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(response => {
            console.log("THE RESPONSE")
            console.log(response.data)
            if (response.data.Message === 'Login Successful') {
                // addingToCart();
                // console.log(response.data)
                props.setJwtUserId(response.data.jwt)
                closeModule();
                console.log("yes")
            }
            else if (response.data.Message === 'Login failed') {
                setPopMessage("Try again");
                console.log(popMessage);
            }
        })
    }

    async function signUp() {
        axios.post(`https://nmvapes.com/eCommerce/api/user_create.php`,
            {
                fullName: first,
                email: em,
                username: last,
                password: password,

            }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then(response => {
            console.log("THE RESPONSE")
            console.log(response.data)
            // setResponse(response.data)
            if (response.data.Message === 'User Created Successfully') {

                props.setJwtUserId(response.data.jwt);
                // addingToCart();
            }
        })
    }

    console.log(props.sessionId)
    
    function addToCart() {
        axios.post(`https://nmvapes.com/eCommerce/api/cart_create.php?session_id=${localStorage.getItem("session_id")}`,
            {
                product_id: id,
                quantity: last,
            }).then(res => {
                props.getCartTotal()
                setResponse(res.data)

                console.log("Kess ekhtek iza ba2a tezbati")
                if (res.data.Message === 'New Item Inserted Inside the Cart' || res.data.Message === 'Quantity updated Successfully') {
                    // closeModule();
                    showModale(true);
                }
            })
        
    }
    console.log("kjLKJLIJL")
    console.log(response)


    useEffect(() => {
        getProductById()
    }, [id]);



    return (
        <div className="px-5 pt-5">
            <div className="flex">
                <Link to="/">
                    <img className="w-4 cursor-pointer" src={home} alt="" />
                </Link>
                <p className="ml-3 pl-3 cursor-pointer border-l-2 border-r-2 border-blue-500 pr-3 text-sm text-blue-500 hover:text-gray-400 hover:border-gray-400">{product?.puff} PUFF</p>
                <p className="ml-3 text-sm text-gray-400">{product?.product_name}</p>
            </div>
            <br />
            <div className="block md:flex">
                {/* <div className="hidden md:block pr-5">
                    <img className="mb-2 w-24 border-2 border-gray-400 rounded" src="https://www.ishtari.com/image/cache/data/system_product/70000/64200/64100/aa1-120x164.jpg?10" alt="" />
                    <img className="mb-2 w-24 border-2 border-gray-400 rounded" src="https://www.ishtari.com/image/cache/data/system_product/70000/64200/64100/1-120x164.jpg?10" alt="" />
                    <img className="mb-2 w-24 border-2 border-gray-400 rounded" src="https://www.ishtari.com/image/cache/data/system_product/70000/64200/64100/2-120x164.jpg?10" alt="" />
                    <img className="mb-2 w-24 border-2 border-gray-400 rounded" src="https://www.ishtari.com/image/cache/data/system_product/70000/64200/64100/a2-120x164.jpg?10" alt="" />
                </div> */}
                <img className="w-96 border-4 border-white" src={product?.image} alt="" />
                <div className="pl-5 w-12/12 md:w-6/12">
                    <p className="text-2xl"><strong>{product?.description}</strong></p>
                    <br />
                    <p className="text-xl text-gray-400">Model Number : {product?.model_nb}</p>
                    <br />
                    <hr></hr>
                    <br />
                    <div className="flex space-x-3">
                        <p className="text-2xl text-gray-400">{product?.puff}</p>
                        <p className="text-2xl text-gray-400 ">PUFF</p>
                    </div>
                    <br />
                    <div className="flex space-x-3">
                        <p className="text-2xl text-gray-400">WAS: </p>
                        <p className="text-2xl text-gray-400 line-through">{product?.P_Was}€</p>
                    </div>
                    <br />
                    <p className="text-2xl text-black">NOW: <strong>{product?.P_Now}€</strong></p>
                    <br />
                    <div className="flex space-x-3">
                        <p className="text-2xl text-black">SAVING: </p>
                        <p className="text-base text-green-600 border rounder bg-green-300 p-2 ">{product?.saving_percentage}% OFF</p>
                    </div>
                    <br />

                    <hr></hr>
                    <br />
                    <div className="flex space-x-5">
                        <input type="number" value={last} onChange={(e) => setLast(e.target.value)} className="border rounded border-gray-500 p-3 w-14" />
                        <button
                            onClick={() => addToCart()}
                            className="w-64 h-12 bg-red-600 text-white rounded-md border-2 border-red-500 cursor-pointer opacity-90 hover:opacity-100">
                            ADD TO CART
                        </button>
                    </div>
                </div>
            </div>
            {/* <br />
            <div>
                <p className="text-3xl border-b-2 border-blue-400 pb-4 w-80 pl-4">
                    <strong>
                        Product Description
                    </strong>
                </p>
                <p className="pl-10">Folding Foot Spa Massager With Heating Foot Massage & 4 Shiatsu Massage Rollers, Bubble, Red Light, & Heat Controlled</p>
                <br />
                <p className="text-xl pl-10"><strong>Features:</strong></p>
                <ul className="pl-20">
                    <li>The fashion style is beautiful and elegant. There is a strong support column inside to prevent falls</li>
                    <li>The folding design is very soft and fashionable</li>
                    <li>It can be placed in any corner when not in use, saving storage space</li>
                    <li>Set a single start button, you can use the remote control for remote operation, no need to bend to set</li>
                </ul>
                <br />
                <p className="text-xl pl-10"><strong> Specification:</strong></p>
                <ul className="pl-20">
                    <li>The fashion style is beautiful and elegant. There is a strong support column inside to prevent falls</li>
                    <li>The folding design is very soft and fashionable</li>
                    <li>It can be placed in any corner when not in use, saving storage space</li>
                    <li>Set a single start button, you can use the remote control for remote operation, no need to bend to set</li>
                </ul>
            </div> */}
            <br />
            <hr></hr>
            <br />
            {/* <div>
                <p className="text-3xl">
                    <strong>
                        Related Product
                    </strong>
                </p>
                <br />
                <ProductCard />
            </div> */}
            {modale ?
                <CartModule modale={modale} showModale={showModale} >
                    <OrderSummary sessionId={props.sessionId} jwtUserId={props.jwtUserId} product={product} modale={modale} showModale={showModale} />
                </CartModule>
                :
                ''
            }
            {(inModule === true && upModule === false) ?
                (
                    <Module>
                        <div className="flex justify-between">
                            <p className="invisible">X</p>
                            <img onClick={() => closeModule()} src={close} className="opacity-90 hover:opacity-100 cursor-pointer w-6 flex justify-center" alt="" />
                        </div>
                        <br />
                        <div >
                            <p className="flex justify-center text-xl text-gray-500">Welcome Back!</p>
                            <p className="flex justify-center text-xl"><strong>Sign in to your account</strong></p>
                        </div>
                        <br />
                        <div className="flex justify-center space-x-4">
                            <p className="text-base text-gray-500">Don't have an account?</p>
                            <p onClick={() => openSignUp()} className="text-base text-blue-500 cursor-pointer underline">Sign Up</p>
                        </div>
                        <br />
                        <input type="text" onChange={(e) => setEmail(e.target.value)} className="ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5" placeholder="Email" />
                        <input type="password" onChange={(e) => setPass(e.target.value)} className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5" placeholder="Password" />
                        <div className="flex justify-center">
                            <button onClick={() => signIn()} className="py-3 px-6  text-white mt-5 bg-blue-700 rounded-lg opacity-90 hover:opacity-100">Sign-In</button>
                        </div>
                        <br />
                        <div>
                            <p className="text-base text-blue-500 cursor-pointer underline flex justify-end">Forget your password?</p>
                        </div>
                    </Module>
                ) : (
                    <div></div>)
            }

            {(upModule === true && inModule === false) ?
                (
                    <Module>
                        <div className="flex justify-between">
                            <p className="invisible">X</p>
                            <img onClick={() => closeModule()} src={close} className="opacity-90 hover:opacity-100 cursor-pointer w-6 flex justify-center" alt="" />
                        </div>
                        <br />
                        <div >
                            <p className="flex justify-center text-xl text-gray-500"><strong>Create an account</strong></p>
                        </div>

                        <div className="flex justify-center space-x-4">
                            <p className="text-base text-gray-500">Already have an account?</p>
                            <p onClick={() => openSignIn()} className="text-base text-blue-500 cursor-pointer underline">Sign In</p>
                        </div>
                        <br />
                        <input type="text" onChange={(e) => setFirst(e.target.value)} className="ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5" placeholder="First Name" />
                        <input type="text" onChange={(e) => setLast(e.target.value)} className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5" placeholder="Last Name" />
                        <input type="text" onChange={(e) => setem(e.target.value)} className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5" placeholder="Email" />
                        <input type="password" onChange={(e) => setPassword(e.target.value)} className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5" placeholder="Password" />
                        <div className="flex justify-center">
                            <button onClick={() => signUp()} className="py-3 px-6  text-white mt-5 bg-blue-700 rounded-lg opacity-90 hover:opacity-100">CREATE AN ACCOUNT</button>
                        </div>

                    </Module>
                ) : (
                    <div></div>)
            }
        </div>

    )
}


export default ProductById;

