import React from "react";


function Module1(props) {
    return (
        <div  className="fixed top-0 left-0 right-0 min-h-screen w-full bg-black bg-opacity-80 z-40 ">
            <div className="shadow-lg fixed bg-white z-50 inset-x-10 md:inset-x-1/3 top-16 w-5/6 h-2/4 md:top-24 md:w-2/6  md:h-2/4 overflow-hidden border-white rounded-xl  mt-20" >
                {props.children}
            </div>

        </div>
    )
}

export default Module1;