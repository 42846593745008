import React from "react";
import useForm from "../useForm";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
//import "./CreditCardForm.css";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";



const CreditCardForm = (props) => {

    const { handleChange, handleFocus, handleSubmit, values, errors } = useForm();
    const confirmation = () => {
        props.openCreditModule(false);
        props.setConfirmButt(true);
    }
    return (
        <div>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"></link>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js" integrity="sha384-7+zCNj/IqJ95wo16oMtfsKbZ9ccEh31eOz1HGyDuCQ6wgnyJNSYdrPa03rtR1zdB" crossorigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.min.js" integrity="sha384-QJHtvGhmr9XOIpI6YVutG+2QOK9T+ZnN4kzFN1RtK3zEFEIsxhlmWl5/YESvpZ13" crossorigin="anonymous"></script>
            <div className="container">
                <div className="box justify-content-center align-items-center">
                    <div className="formDiv">
                        <div className="creditCard">
                            <Cards
                                cvc={values.cardSecurityCode}
                                expiry={values.cardExpiration}
                                focused={values.focus}
                                name={values.cardName}
                                number={values.cardNumber}
                            />
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <div className="flex justify-between mt-3 w-12/12 space-x-10">
                                <div className=" w-6/12">
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            id="cardName"
                                            data-testid="cardName"
                                            name="cardName"
                                            placeholder="Cardholder Name"
                                            value={values.cardName}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            isValid={errors.cname}
                                        />
                                    </Form.Group>
                                </div>
                                <div className=" w-6/12">
                                    <Form.Group>
                                        <Form.Control
                                            type="number"
                                            id="cardNumber"
                                            data-testid="cardNumber"
                                            name="cardNumber"
                                            placeholder="Card Number"
                                            value={values.cardNumber}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            isValid={errors.cnumber}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="flex justify-between mt-3 w-12/12 space-x-10">
                                <div className=" w-6/12">
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    id="cardExpiration"
                                                    data-testid="cardExpiration"
                                                    name="cardExpiration"
                                                    placeholder="Expiration Date"
                                                    value={values.cardExpiration}
                                                    onChange={handleChange}
                                                    onFocus={handleFocus}
                                                    isValid={errors.cexp}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                <div className=" w-6/12">
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control
                                                    type="number"
                                                    id="cardSecurityCode"
                                                    data-testid="cardSecurityCode"
                                                    name="cardSecurityCode"
                                                    placeholder="Security Code"
                                                    value={values.cardSecurityCode}
                                                    onChange={handleChange}
                                                    onFocus={handleFocus}
                                                    isValid={errors.ccvv}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="flex justify-center mt-3 mb-3 ">
                                {/* onClick={()=>props.openCreditModule(false)} */}
                                <Button
                                    size={"block"}
                                    data-testid="validateButton"
                                    id="validateButton"
                                    type="submit"
                                >
                                    VALIDATE
                                </Button>
                            </div>
                        </Form>
                    </div>

                    <Alert
                        id="alertMessage"
                        data-testid="alertMessage"
                        variant={errors.variant}
                        show={errors.show}
                    >
                        {errors.message}

                    </Alert>{" "}
                    {errors.variant == "success" ?
                        <div className="flex justify-between pr-5 mb-5">
                            <p className="invisible">X</p>
                            <input type="button" onClick={() => confirmation()} className="bg-blue-500 text-white border border-blue-500 rounded-full py-2 px-4 cursor-pointer opacity-100 hover:opacity-80" value="NEXT" />
                        </div> :
                        ''
                    }
                </div>
            </div>
        </div>
    );
};

export default CreditCardForm;