import React from "react";
import { Carousel } from 'react-carousel-minimal';
import banner1 from "../../assets/banner-1.JPEG";
import banner2 from "../../assets/banner-2.jpeg";
import banner3 from "../../assets/banner-3.JPG";


function Slider() {
    const data = [
        // {
        //     image: "https://img1.wsimg.com/isteam/ip/f7a0f403-e632-4d3d-8025-2bff4b09aa12/WhatsApp%20Image%202021-12-15%20at%206.47.09%20PM%20(2).jpeg/:/rs=w:1160,h:798",
        //     caption: "San Francisco"
        // }
        // ,
        // {
        //     image: "https://nmvapes.com/static/media/message.fb6a0f9813e57d93cdc7.jpeg",
        //     caption: "Scotland"
        // },
        {
            image: "https://nmvapes.com/static/media/banner-1.ea892fb098afc6243325.JPEG",
            caption: "Scotland"
        }

        ,
        {
            image: "https://nmvapes.com/static/media/banner-2.4dfcee4c0448b1654a99.jpeg",
            caption: "Darjeeling"
        },
        {
            image: "https://nmvapes.com/static/media/banner-3.000212f0b380ad475d90.JPG",
            caption: "San Francisco"
        }
        // ,
        // {
        //     image: "https://drive.google.com/uc?id=1qg6vILQ0iMwO1wXMrMGYhvIEAaKBLowA",
        //     caption: "Scotland"
        // }
        // ,
        // {
        //     image: "https://www.tusktravel.com/blog/wp-content/uploads/2020/07/Best-Time-to-Visit-Darjeeling-for-Honeymoon.jpg",
        //     caption: "Darjeeling"
        // },
        // {
        //     image: "https://www.omm.com/~/media/images/site/locations/san_francisco_780x520px.ashx",
        //     caption: "San Francisco"
        // },
        // {
        //     image: "https://images.ctfassets.net/bth3mlrehms2/6Ypj2Qd3m3jQk6ygmpsNAM/61d2f8cb9f939beed918971b9bc59bcd/Scotland.jpg?w=750&h=422&fl=progressive&q=50&fm=jpg",
        //     caption: "Scotland"
        // },
        // {
        //     image: "https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg",
        //     caption: "Darjeeling"
        // }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
        visibility: 'hidden'
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }
    return (
        <div className="App">
            <div style={{ textAlign: "center" }}>
                <div style={{
                    //padding: "0 20px"
                }}>
                    <Carousel
                        data={data}
                        time={3000}
                        width="100%"
                        // 350px
                        height="500px"
                        captionStyle={captionStyle}
                        //radius="10px"
                        //slideNumber={true}
                        slideNumberStyle={slideNumberStyle}
                        captionPosition="bottom"
                        automatic={true}
                        dots={true}
                        pauseIconColor="white"
                        pauseIconSize="40px"
                        slideBackgroundColor="darkgrey"
                        slideImageFit="cover"
                        //thumbnails={true}
                        //thumbnailWidth="100px"
                        style={{
                            textAlign: "center",
                            maxWidth: "100%",
                            maxHeight: "500px",
                            //margin: "40px auto",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Slider;