import React from "react";
import Slider from "./Slider";
import ProductCard from "./ProductCard";
import made from "../../assets/made.png";
import madefr from "../../assets/madefr.jpeg";
import partners from "../../assets/partners.PNG";
import mane from "../../assets/mane.PNG";
import royale from "../../assets/royale.PNG";
import maneSokkar from "../../assets/maneSokkar.PNG";
import contactus from "../../assets/contactus.png";
import message from "../../assets/message.jpeg";
import sokkarLogo from "../../assets/sokkarLogo.PNG";
import sokkMane from "../../assets/sokkMane.PNG";
import { Link } from "react-router-dom";
import pdf1 from "../../assets/NM-Vapes-catalogue-1.pdf";
import pdf2 from "../../assets/NM-Vapes-catalogue-2.pdf";
import pdf from "../../assets/NM-Catalogue.pdf";

function Body() {
  return (
    <div className="App  relative">
      <Slider />
      <div className="px-5">
        <p className="mt-10 flex justify-center text-4xl md:text-5xl text-amber-600 ">
          Our Products
        </p>
        <ProductCard />
      </div>
      {/* <div className="mt-10 px-10">
                <p className="flex justify-center text-4xl md:text-5xl text-amber-600 mb-10">About Us</p>
                <p className="text-gray-500 text-lg ">- NM x SOKKAR strives to provide the most satisfactory service. our concept is inspired by passion, innovation, and taste. We combine the connoisseur’s mind and a scientific approach to make our vision come to life.We provide a variety of flavors of e-juice to meet the needs of different customers. Professional taste customization service does not miss any taste that customers want. We have an experienced technical R&D team, with many years of experience in flavor research and development, to ensure that our products can meet your requirements 100%. If you have any questions or business consultation, you are warmly welcome to contact us! info@sokkar.uk.</p>
                <p className="text-gray-500 text-lg mt-2">- NM x SOKKAR vapes are manufactured with a high-standard, dust-free purification production workshop, modern testing and testing center laboratory equipment, also with various advanced production equipment. For example battery performance test system, resistance tester, eight-station life Tester, electromagnetic vibration tester, drop tester, capsule machine, laser carving, etc.</p>
            </div> */}
      <div className="mt-10 px-10">
        <p className="flex justify-center text-4xl md:text-5xl text-gray-700 mb-10">
          Message from the CEO
        </p>
        <p className="text-gray-500 text-lg ">
          - Manufacture Royale des Tabacs is committed to provide the best
          quality flavors,thus the entire process of Processing, Flavoring,
          Filling, and Packaging follows strict International and French
          standards. As such, regular quality testing by qualified and
          experienced professionals, ensures that our products conform to the
          latest quality standards. All our raw materials are sourced from
          different manufacturers throughout Europe. We also provide customized
          flavors depending on the client's country demand.{" "}
        </p>
        <p className="text-gray-500 text-lg mt-2">
          - Not to forget to mention that our products are certified from French
          Health Ministry for its natural materials which differentiate us from
          other brands that use more chemicals and last less than ours.
        </p>
        <p className="text-gray-500 text-lg mt-2">
          - SOKKAR disposable vapes (E- Cigarettes) VAPE ELECTRONIQUE JETABLE
          are UN38.3, TPD, ROHS, and CE certified. NM x SOKKAR strives to
          provide the most satisfactory service. Our concept is inspired by
          passion, innovation, and taste. We combine the connoisseur’s mind and
          a scientific approach to make our vision come to life. We provide a
          variety of flavors of e-juice to meet the needs of different
          customers. Professional taste customization service does not miss any
          taste that customers want. We have an experienced technical R&D team,
          with many years of experience in flavor research and development, to
          ensure that our products can meet your requirements 100%. You are
          warmly welcome to contact us! info@sokkar.uk
        </p>
        <p className="flex justify-end text-blue-600 text-xl ">
          <i>Eng Nizar Melhem</i>{" "}
        </p>
      </div>

      <div className="mt-10 px-10 flex  justify-center">
        <div>
          <p className="flex justify-center text-4xl md:text-5xl text-gray-700 mb-10">
            NM Catalogues
          </p>
          <div className="flex justify-center">
          {/* sm:block md:flex justify-center sm:pl-10 md:pl-0 */}
            <p className="text-gray-500 text-xl ">
              <a
                className="bg-white hover:bg-amber-500 pt-3 pb-3 pr-5 pl-5 border-2 border-gray-700 rounded-md"
                target="blank"
                href={pdf}
              >
                <strong>Catalogue</strong>
              </a>
            </p>
            <div className="mt-10 md:mt-0">
            {/* <p className="text-gray-500 text-xl sm:ml-0 md:ml-10  ">
              <a
                className="bg-white hover:bg-amber-500 pt-3 pb-3 pr-5 pl-5 border-2 border-gray-700 rounded-md"
                target="blank"
                href={pdf2}
              >
                <strong>Catalogue 2</strong>
              </a>
            </p> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="h-5/6"> */}
      {/* forHeight w-full px-10 */}
      <div className="mt-10 flex justify-center">
        <img className=" w-4/6 h-4/6 md:w-2/6 md:h-2/6" src={message} alt="" />
      </div>
      {/* </div> */}
      <div className="mt-10">
        <p className="flex justify-center text-4xl md:text-5xl text-amber-600 mb-10">
          Our Partners
        </p>

        <div className="flex justify-center px-10 h-14 md:h-auto">
          <img src={royale} alt="" />
        </div>
        <div className="mt-5 h-24 md:h-auto flex justify-center px-10">
          <img src={sokkMane} alt="" />
        </div>
      </div>

      <div className="flex justify-end pr-10">
        <img className="w-20 md:w-40" src={madefr} alt="" />
      </div>

      {/* <div className="flex justify-center pr-10 space-x-16 mb-28">
                <img className="" src={sokkarLogo} alt="" />
                <img className="" src={mane} alt="" />
            </div> */}

      <Link to="/contact-us">
        <div className="flex justify-end ">
          <img
            className="w-36 md:w-44 px-10 fixed bottom-10"
            src={contactus}
            alt=""
          />
        </div>
      </Link>
    </div>
  );
}

export default Body;
