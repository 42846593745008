import React, { useState } from "react";
import menu from "../assets/menu.png";
import { Link } from "react-router-dom";


function PhoneSubHeader(props) {
    const [puffs, setPuffs] = useState(false);
    return (
        <div className=" block ">
            <div className="px-10 py-3 bg-gray-100">
                <div className="flex  space-x-8">
                    <div className="mt-2"><strong>MENU</strong></div>
                    <div>
                        <img onClick={() => props.setBar(!props.bar)} className="w-10 cursor-pointer" src={menu} alt="" />
                        {props.bar ?
                            <div className="text-sm md:text-base mt-5">
                                <Link to="/"><p className="hover:text-gray-500"><strong>Home</strong></p></Link>
                                <div className="flex">
                                    <p className="cursor-pointer mt-3 hover:text-gray-500" onClick={() => setPuffs(!puffs)}><strong>Puffs</strong></p>
                                    {puffs ?
                                        <div className="mt-2 ml-5 border-2 border-gray-500 p-3 bg-white rounded-lg">
                                            <a href="#700"><p className="hover:text-gray-500 cursor-pointer"><strong>700 PUFF</strong></p></a>
                                            <a href="#1800"><p className="hover:text-gray-500 mt-3 cursor-pointer"><strong>1800 PUFF</strong></p></a>
                                            <a href="#2500"><p className="hover:text-gray-500 mt-3 cursor-pointer"><strong>2500 PUFF</strong></p></a>
                                        </div> :
                                        ''}
                                </div>
                                <Link to="/contact-us"><p className="mt-2 hover:text-gray-500"><strong>Contact Us</strong></p></Link>
                            </div>
                            :
                            ''}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PhoneSubHeader;