import React from "react";


// function CartModule(props) {
//     return (
//         <div  className="fixed  top-0 left-0 right-0 min-h-screen w-full bg-black bg-opacity-30 z-40">
//             <div className=" shadow-lg fixed  bg-white z-50  inset-x-1/3 top-24 w-2/6  h-3/4 overflow-hidden border-white rounded-xl p-4  " >
//                 {props.children}
//             </div>

//         </div>
//     )
// }

// export default CartModule;


function CartModule(props) {
    return (
        <div >
            <div onClick={() => props.showModale(false)} className="fixed  top-0 left-0 right-0 min-h-screen w-full bg-black bg-opacity-30 z-40 pt-16">
                <div  className=" shadow-lg fixed  bg-white z-50  right-0 w-full md:w-1/3  h-screen overflow-scroll p-4  pb-16" >
                    {props.children}
                </div>
            </div>
        </div>

    )
}

export default CartModule;