import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CheckModule from "./CheckModule";
import close from "../assets/close.png";
import CreditCardForm from "./creditCard/comp/CreditCardForm";
import Module1 from "./Module1";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";




function Checkout(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [zone, setZone] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [addressDetails, setAddressDetails] = useState("");
    const [paymentMethod, setPaymentMethod] = useState();

    const [cardHolderName, setCardHolderName] = useState("hhh");
    const [cardNumber, setCardNumber] = useState("1234");
    const [expirationDate, setExpirationDate] = useState("5353");
    const [CVC, setCVC] = useState("555");

    const [creditModule, openCreditModule] = useState(false);
    const [adressNext, setAdressNext] = useState(false);
    const [viewNext, setViewNext] = useState(false);
    const [confirmButt, setConfirmButt] = useState(false);

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [zoneError, setZoneError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [cashError, setCashError] = useState("");

    
    const [verif, setVerif] = useState(false);


    async function checkOutProducts() {
        axios.post(`https://nmvapes.com/eCommerce/api/checkout_create.php?session_id=${localStorage.getItem("session_id")}`, {
            firstName: firstName,
            lastName: lastName,
            Zone: zone,
            Address: address,
            phone: phone,
            addressDetails: addressDetails,
            paymentMethod: paymentMethod,
            cardHolderName: cardHolderName,
            cardNumber: cardNumber,
            expirationDate: expirationDate,
            CVC: CVC,

        }, {
            headers: {
                // 'Authorization': props.jwtUserId,
                // 'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log("THE RESPONSE");
            console.log(response.data);
            // setResponse(response.data)
            if (response.data.Message === "Payment Operation Succeded") {
                setVerif(true);
                //localStorage.setItem("session_id", "")
            }
        })
    }


    const openCreditCard = (e) => {
        setPaymentMethod(e.target.value);
        openCreditModule(true);
        setViewNext(true);
    }
    const setDeliveryCash = (e) => {
        setPaymentMethod(e.target.value);
        openCreditModule(false);
        setViewNext(true);
    }

    useEffect(() => {
        setFirstNameError("");
    }, [firstName])
    useEffect(() => {
        setLastNameError("");
    }, [lastName])
    useEffect(() => {
        setZoneError("");
    }, [zone])
    useEffect(() => {
        setAddressError("");
    }, [address])
    useEffect(() => {
        setPhoneError("");
    }, [phone])
    useEffect(() => {
        setCashError("");
    }, [paymentMethod])


    // let next = true;
    const onNext = () => {

        if (firstName.length === 0) {
            setFirstNameError("First Name is Required Please!");
        }
        if (lastName.length === 0) {
            setLastNameError("Last Name is Required Please!");
        }
        if (phone.length === 0) {
            setPhoneError("Phone Number is Required Please!");
        }
        if (address.length === 0) {
            setAddressError("Address Information is Required Please!");
        }
        if (zone.length === 0) {
            setZoneError("Zone Area is Required Please!");
        }
        if (firstName.length !== 0 && lastName.length !== 0 && phone.length !== 0 && address.length !== 0 && zone.length !== 0) {
            setAdressNext(true);
        }
    }

    console.log("THE ADRESS NEXT")
    console.log(adressNext)
    console.log(firstName.length)
    console.log(lastName.length)
    console.log(phone.length)
    console.log("paymentMethod")
    console.log(paymentMethod)

    const onSecondNext = ()=> {
        if (paymentMethod === undefined) {
            setCashError("Please Specify Your Payment Method!");
        }
    }

    const setOrderSucc=()=>{
        localStorage.setItem("session_id", "");
        setVerif(false);
        window.location.reload(false);
    }

    return (
        <div>
            <h1 className="text-2xl pb-5 px-10 pt-5"><strong>Checkout</strong></h1>
            <div className="block md:flex justify-between pl-10 pr-10 w-12/12">

                <div className="w-12/12 md:w-8/12">
                    <div className="w-12/12 border border-gray-300 rounded shadow-lg hover:shadow-md hover:shadow-gray-500	">
                        <div className="flex  p-5">
                            <img src="" alt="" />
                            <p className="text-xl">Address Information:</p>
                        </div>
                        <div className="pl-5 pr-5 w-12/12">
                            <br />
                            <div className="block md:flex md:space-x-20 w-12/12">
                                <div className="w-12/12 md:w-6/12">
                                    <div className="block md:flex md:space-x-2 items-center ">
                                        <label htmlFor="first_name" className="w-12/12 md:w-3/12">First Name:</label>
                                        {firstNameError === "" ?
                                            <input onChange={(e) => (setFirstName(e.target.value))} id="first_name" className="border border-gray-400 rounded-md p-3 w-11/12 md:w-9/12" type="text" />
                                            :
                                            <input onChange={(e) => (setFirstName(e.target.value))} id="first_name" className="border border-red-600 rounded-md p-3 w-11/12 md:w-9/12" type="text" />
                                        }
                                    </div>
                                    <div className="text-red-600">{firstNameError}</div>
                                </div>
                                <div className="w-12/12 md:w-6/12 mt-5 md:mt-0">
                                    <div className="block md:flex md:space-x-2 items-center ">
                                        <label htmlFor="last_name" className="w-3/12">Last Name:</label>
                                        {lastNameError === "" ?
                                            <input onChange={(e) => (setLastName(e.target.value))} id="last_name" className=" border border-gray-400 rounded-md p-3 w-11/12 md:w-9/12" type="text" />
                                            :
                                            <input onChange={(e) => (setLastName(e.target.value))} id="last_name" className=" border border-red-600 rounded-md p-3 w-11/12 md:w-9/12" type="text" />
                                        }
                                    </div>
                                    <div className="text-red-600">{lastNameError}</div>
                                </div>
                            </div>
                            <br />
                            <div className="block md:flex md:space-x-20 w-12/12">
                                <div className="w-12/12 md:w-6/12">
                                    <div className="block md:flex md:space-x-2 items-center ">
                                        <label htmlFor="zone" className="w-3/12">Zone Area:</label>
                                        {zoneError === "" ?
                                            <input onChange={(e) => (setZone(e.target.value))} id="zone" className="border border-gray-400 rounded-md  p-3  w-11/12 md:w-9/12" type="text" />
                                            :
                                            <input onChange={(e) => (setZone(e.target.value))} id="zone" className="border border-red-600 rounded-md  p-3  w-11/12 md:w-9/12" type="text" />
                                        }
                                    </div>
                                    <div className="text-red-600">{zoneError}</div>
                                </div>
                                <div className="w-12/12 md:w-6/12 mt-5 md:mt-0">
                                    <div className="block md:flex md:space-x-2 items-center ">
                                        <label htmlFor="address" className="w-3/12">Address:</label>
                                        {addressError === "" ?
                                            <input onChange={(e) => (setAddress(e.target.value))} id="address" className="border border-gray-400 rounded-md p-3 w-11/12 md:w-9/12" type="text" />
                                            :
                                            <input onChange={(e) => (setAddress(e.target.value))} id="address" className="border border-red-600 rounded-md p-3 w-11/12 md:w-9/12" type="text" />
                                        }
                                    </div>
                                    <div className="text-red-600">{addressError}</div>
                                </div>
                            </div>
                            <br />
                            <div className="block md:flex md:space-x-20 w-12/12 pb-10">
                                <div className="w-12/12 md:w-6/12">
                                    <div className="block md:flex md:space-x-2 items-center ">
                                        <label htmlFor="phone_number" className="w-3/12">Phone Number:</label>
                                        {phoneError === "" ?
                                            <input onChange={(e) => (setPhone(e.target.value))} id="phone_number" className="border border-gray-400 rounded-md p-3 w-11/12 md:w-9/12" type="number" />
                                            :
                                            <input onChange={(e) => (setPhone(e.target.value))} id="phone_number" className="border border-red-600 rounded-md p-3 w-11/12 md:w-9/12" type="number" />
                                        }
                                    </div>
                                    <div className="text-red-600">{phoneError}</div>
                                </div>
                                <input onChange={(e) => (setAddressDetails(e.target.value))} className="border border-gray-400 rounded-md bg-gray-100 p-4 w-11/12 md:w-6/12 mt-5 md:mt-0" type="text" placeholder="More Adress Details" />
                            </div>
                        </div>
                        <div className="flex justify-between pr-5 mb-5">
                            <p className="invisible">X</p>
                            <input type="button" onClick={() => onNext()} className="bg-blue-500 text-white border border-blue-500 rounded-full py-2 px-4 cursor-pointer opacity-70 hover:opacity-100" value="NEXT" />
                        </div>
                    </div>
                    {adressNext === true ?
                        <div className="w-12/12 md:w-8/12  pt-5 ">
                            <div className="w-12/12 md:w-8/12 border border-gray-300 rounded shadow-lg hover:shadow-md hover:shadow-gray-500">
                                <div className="flex  p-5">
                                    <img src="" alt="" />
                                    <p className="text-xl">Cash Method</p>
                                </div>
                                <div className="pl-5 ">
                                    <div className="flex space-x-5 items-center">
                                        <input onClick={(e) => setDeliveryCash(e)} type="radio" name="cash_meth" value="delivery" />
                                        <label >Cash On delivery</label><br />
                                    </div>
                                    {/* <div className="flex space-x-5 items-center">
                                        <input onClick={(e) => openCreditCard(e)} type="radio" name="cash_meth" value="cart" />
                                        <label >Cash By Credit Card</label><br />
                                    </div> */}
                                </div>
                                <div className="pl-5 text-red-600">{cashError}</div>
                                {viewNext ?
                                    <div className="flex justify-between pr-5 mb-5">
                                        <p className="invisible">X</p>
                                        <input type="button" onClick={() => setConfirmButt(true)} className="bg-blue-500 text-white border border-blue-500 rounded-full py-2 px-4 cursor-pointer opacity-70 hover:opacity-100" value="NEXT" />
                                    </div> :
                                    // <div className="pt-5"></div>
                                    <div className="flex justify-between pr-5 mb-5">
                                        <p className="invisible">X</p>
                                        <input type="button" onClick={() => onSecondNext()} className="bg-blue-500 text-white border border-blue-500 rounded-full py-2 px-4 cursor-pointer opacity-70 hover:opacity-100" value="NEXT" />
                                    </div>
                                }
                            </div>
                        </div> :
                        ''
                    }
                </div>
                <div className="w-12/12 md:w-3/12 block  border h-full p-5 border-gray-300 rounded shadow-lg hover:shadow-md hover:shadow-gray-500 mt-5 md:mt-0">
                    <div className="text-lg text-black">
                        Order Summary
                    </div>
                    <br />
                    <div className="space-x-2 text-gray-300 flex">
                        <div>Subtotal</div>
                        <div className="flex ">
                            <div>&#40;</div>
                            <div className="flex space-x-1">
                                <div>{props.cartTotal?.Items_Count}</div>
                                <div>items</div>
                            </div>
                            <div>&#41;</div>
                        </div>
                    </div>
                    <br />
                    <div className="text-lg text-black">Order Totals</div>
                    <br />
                    <div>
                        <div className="flex justify-between">
                            <div>Sub-Total</div>
                            <div>{props.cartTotal?.Total_Amount} €</div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className="flex justify-between">
                            <div>Delivery</div>
                            <div>1.5 €</div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className="flex justify-between">
                            <div>Total</div>
                            <div>{props.cartTotal?.Total_Amount + 1.5} €</div>
                        </div>
                    </div>
                    <br />

                    {confirmButt ?
                        <button onClick={() => checkOutProducts()} className="bg-blue-600 p-3 border rounded text-white w-full" type="button" >CONFIRM ORDER</button> :
                        <button className="cursor-not-allowed opacity-40 bg-blue-600 p-3 border rounded text-white w-full" type="button" >CONFIRM ORDER</button>}

                </div>
            </div>


            <br />
            {creditModule ?
                <CheckModule>
                    <div className="flex justify-between">
                        <p className="invisible">X</p>
                        <img onClick={() => openCreditModule(false)} src={close} className="opacity-90 hover:opacity-100 cursor-pointer w-6 h-6 flex justify-center" alt="" />
                    </div>

                    <CreditCardForm openCreditModule={openCreditModule} setConfirmButt={setConfirmButt} />
                </CheckModule>
                :
                ''
            }

            {
                verif ?
                    <Module1>
                        <div className="relative text-align-center ">
                            <img className="w-full h-6/12" src="https://img1.wsimg.com/isteam/stock/gpKN1pP/:/cr=t:6.15%25,l:0%25,w:100%25,h:65.23%25/rs=w:515,h:235,cg:true" alt="" />
                            {/* <Link to="/"> */}
                            <img onClick={() => setOrderSucc()} className="w-6 cursor-pointer text-base absolute top-2 right-4  " src={close} alt="" />
                            {/* </Link> */}
                        </div>
                        <p className="flex justify-center text-2xl text-amber-600 mt-3">You Ordered Successfully</p>
                        <p className="flex justify-center text-base text-gray-400 mt-2">your order will arrive within 2 days maximum</p>
                    </Module1> :
                    <div></div>
            }
        </div>
    );
}

export default Checkout;



