import React, { useState, useEffect } from "react";
import Header from "./components/Header.jsx";
import Body from "./components/body/Body.jsx";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import ProductById from "./components/ProductById.jsx";
import SubHeader from "./components/SubHeader.jsx";
import PhoneSubHeader from "./components/PhoneSubHeader.jsx";
import Module from "./components/Module.jsx";
import close from "./assets/close.png";
import Cart from "./components/Cart.jsx";
import Checkout from "./components/Checkout.jsx";
import axios from "axios";
import Attention from "./components/Attention.jsx";
import Module1 from "./components/Module1.jsx";
import Footer from "./components/Footer.jsx";
import ContactUs from "./components/ContactUs.jsx";

function App() {
  const openSignUp = () => {
    setInModule(false);
    setUpModule(true);
  };
  const openSignIn = () => {
    setInModule(true);
    setUpModule(false);
  };
  const closeModule = () => {
    setInModule(false);
    setUpModule(false);
  };
  const openMenu = () => {
    setMenu(true);
  }
  const closeMenu = () => {
    setMenu(false);
  }
  const [inModule, setInModule] = useState(false);
  const [upModule, setUpModule] = useState(false);
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [response, setResponse] = useState();
  const [first, setFirst] = useState();
  const [last, setLast] = useState(1);
  const [em, setem] = useState();
  const [password, setPassword] = useState();
  const [popMessage, setPopMessage] = useState();
  const [jwtUserId, setJwtUserId] = useState();
  const { id } = useParams();
  const [attention, setAttention] = useState(true);
  const [module1, setModule1] = useState(true);
  const [bar, setBar] = useState(false);
  const [menu, setMenu] = useState(false);

  const [cartTotal, setCartTotal] = useState();
  const [sessionId, setSessionId] = useState("");
  const [sessionResponse, setSessionResponse] = useState("");

  // window.location.reload(false);

  useEffect(() => {
    getCartTotal();
  }, [cartTotal?.Items_Count]);

  useEffect(() => {
    if (
      localStorage.getItem("session_id") === "" ||
      localStorage.getItem("session_id") === null ||
      sessionResponse === "Session Creating Failed"
    ) {
      axios
        .post("https://nmvapes.com/eCommerce/api/session.php", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setSessionResponse(response.data.Message);
          if (
            response.data.Message === "New Session Has Been Generated" ||
            response.data.Message === "Session Already Exist"
          ) {
            // addToCart();

            console.log("response.data.session_id");
            console.log(response.data.session_id);
            setSessionId(response.data.session_id);
            localStorage.setItem("session_id", response.data.session_id);
            console.log("localStorage");
            console.log(localStorage.getItem("session_id"));
          }
        });
    } else {
      console.log("else");
      console.log(localStorage.getItem("session_id"));
    }
  }, []);

  async function getCartTotal() {
    // console.log("what is wrong")
    // console.log(jwtUserId)
    axios
      .get(
        `https://nmvapes.com/eCommerce/api/cart_total_amount.php?session_id=${localStorage.getItem(
          "session_id"
        )}`,
        {
          headers: {
            // 'Authorization': jwtUserId
          },
        }
      )
      .then((res) => {
        setCartTotal(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  // console.log("THE CARTTOTAL");
  // console.log(cartTotal);

  async function signIn() {
    axios
      .post(
        `https://nmvapes.com/eCommerce/api/user_login.php`,
        {
          email: email,
          password: pass,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.Message === "Login Successful") {
          setJwtUserId(response.data.jwt);
          closeModule();
        } else if (response.data.Message === "Login failed") {
          setPopMessage("Try again");
        }
      });
  }

  async function signUp() {
    axios
      .post(
        `https://nmvapes.com/eCommerce/api/user_create.php`,
        {
          fullName: first,
          email: em,
          username: last,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("User Added Successfully");
        console.log(response.data.Message);
        if (
          response.data.Message === "User Added Successfully" ||
          response.data.Message === "user already exist"
        ) {
          closeModule();
        }
      });
  }

  return (
    <div className="sinFrans">
      <Router>
        {attention === true ? (
          <Attention setAttention={setAttention} attention={attention} />
        ) : (
          <div></div>
        )}
        <Header
          cartTotal={cartTotal}
          jwtUserId={jwtUserId}
          setJwtUserId={setJwtUserId}
          inModule={module}
          setInModule={setInModule}
          menu={menu}
          setMenu={setMenu}
        />
        {/* <div className="hidden md:block">
          <SubHeader />
        </div> */}
        {/* <div className="block md:hidden"> */}
        {/* <PhoneSubHeader bar={bar} setBar={setBar} /> */}
        {/* </div> */}
        <Routes>
          <Route path="/" exact element={<Body />} />
          <Route
            path="/product/:id"
            exact
            element={
              <ProductById
                sessionId={sessionId}
                getCartTotal={getCartTotal}
                jwtUserId={jwtUserId}
                setJwtUserId={setJwtUserId}
                inModule={module}
                setInModule={setInModule}
              />
            }
          />
          <Route
            path="/cart"
            exact
            element={
              <Cart
                getCartTotal={getCartTotal}
                sessionId={sessionId}
                cartTotal={cartTotal}
                jwtUserId={jwtUserId}
                setJwtUserId={setJwtUserId}
              />
            }
          />
          <Route
            path="/checkout"
            exact
            element={
              <Checkout
                sessionId={sessionId}
                cartTotal={cartTotal}
                jwtUserId={jwtUserId}
                setJwtUserId={setJwtUserId}
              />
            }
          />
          <Route path="/contact-us" exact element={<ContactUs />} />
        </Routes>
        <Footer />
      </Router>

      {inModule === true && upModule === false ? (
        <Module>
          <div className="flex justify-between">
            <p className="invisible">X</p>
            <img
              onClick={() => closeModule()}
              src={close}
              className="opacity-90 hover:opacity-100 cursor-pointer w-6 flex justify-center"
              alt=""
            />
          </div>
          <br />
          <div>
            <p className="  flex justify-center text-xl text-gray-500">
              Welcome Back!
            </p>
            <p className=" flex justify-center text-xl">
              <strong>Sign in to your account</strong>
            </p>
          </div>
          <br />
          <div className="flex justify-center space-x-4">
            <p className=" text-base text-gray-500">Don't have an account?</p>
            <p
              onClick={() => openSignUp()}
              className=" text-base text-blue-500 cursor-pointer underline"
            >
              Sign Up
            </p>
          </div>
          <br />
          <input
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            className="ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5"
            placeholder="Email"
          />
          <input
            type="password"
            onChange={(e) => setPass(e.target.value)}
            className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5"
            placeholder="Password"
          />
          <div className="flex justify-center">
            <button
              onClick={() => signIn()}
              className=" py-3 px-6  text-white mt-5 bg-blue-700 rounded-lg opacity-90 hover:opacity-100"
            >
              Sign-In
            </button>
          </div>
          <br />
          <div>
            <p className=" text-base text-blue-500 cursor-pointer underline flex justify-end">
              Forget your password?
            </p>
          </div>
        </Module>
      ) : (
        <div></div>
      )}

      {upModule === true && inModule === false ? (
        <Module>
          <div className="flex justify-between">
            <p className="invisible">X</p>
            <img
              onClick={() => closeModule()}
              src={close}
              className="opacity-90 hover:opacity-100 cursor-pointer w-6 flex justify-center"
              alt=""
            />
          </div>
          <br />
          <div>
            <p className=" flex justify-center text-xl text-gray-500">
              <strong>Create an account</strong>
            </p>
          </div>

          <div className="flex justify-center space-x-4">
            <p className=" text-base text-gray-500">Already have an account?</p>
            <p
              onClick={() => openSignIn()}
              className=" text-base text-blue-500 cursor-pointer underline"
            >
              Sign In
            </p>
          </div>
          <br />
          <input
            type="text"
            onChange={(e) => setFirst(e.target.value)}
            className="ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5"
            placeholder="First Name"
          />
          <input
            type="text"
            onChange={(e) => setLast(e.target.value)}
            className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5"
            placeholder="Last Name"
          />
          <input
            type="text"
            onChange={(e) => setem(e.target.value)}
            className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5"
            placeholder="Email"
          />
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className="mt-5 ml-4 border rounded-lg border-gray-500 w-11/12 h-12 p-5"
            placeholder="Password"
          />
          <div className="flex justify-center">
            <button
              onClick={() => signUp()}
              className=" py-3 px-6  text-white mt-5 bg-blue-700 rounded-lg opacity-90 hover:opacity-100"
            >
              CREATE AN ACCOUNT
            </button>
          </div>
        </Module>
      ) : (
        <div></div>
      )}

      {module1 ? (
        <Module1>
          <div className="relative text-align-center ">
            <img
              className="w-full h-6/12"
              src="https://img1.wsimg.com/isteam/stock/gpKN1pP/:/cr=t:6.15%25,l:0%25,w:100%25,h:65.23%25/rs=w:515,h:235,cg:true"
              alt=""
            />
            <img
              onClick={() => setModule1(false)}
              className="w-6 cursor-pointer text-base absolute top-2 right-4  "
              src={close}
              alt=""
            />
          </div>
          <p className="flex justify-center text-2xl text-amber-600 mt-3">
            Welcome to NM Vapes
          </p>
          <p className="flex justify-center text-lg text-gray-500 mt-2 px-3">
            I Confirm That I'm Over 18 Years of Age
          </p> 
          <div className="flex justify-center">
            <button onClick={() => setModule1(false)} className="text-xl text-white bg-amber-600 hover:bg-amber-500 pr-3 pl-3 mt-3 border-amber-600 rounded-md">
              YES
            </button>
          </div>
        </Module1>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default App;
