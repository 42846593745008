import React from "react";


function Module(props) {
    return (
        <div  className="fixed  top-0 left-0 right-0 min-h-screen w-full bg-black bg-opacity-30 z-40">
            {/* <div className=" shadow-lg fixed  bg-white z-50  inset-x-1/3 top-24 w-2/6  h-3/4 overflow-hidden border-white rounded-xl p-4  " > */}
            <div className="shadow-lg fixed bg-white z-50 inset-x-10 md:inset-x-1/3 top-16 w-5/6 h-3/4 md:top-24 md:w-2/6  md:h-3/4 overflow-hidden border-white rounded-xl p-4 mt-20 md:mt-5 ">
                {props.children}
            </div>

        </div>
    )
}

export default Module;

// inset-x-1/4 top-24 w-3/6  h-3/4