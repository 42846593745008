import React, { useEffect, useState } from "react";
import axios from "axios";
import close from "../assets/close.png";
import Module1 from "./Module1";

function ContactUs(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState();
    const [verif, setVerif] = useState(false);

    // const [module1, setModule1] = useState(false);
    // const [response, setResponse] = useState();


    async function contactUsFunction() {
        axios.post(`https://nmvapes.com/eCommerce/api/send_message.php`, {
            Name: name,
            Email: email,
            Phone: phone,
            Message: message
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log("THE RESPONSE")
            console.log(response.data)
            // setResponse(response.data)
            if (response.data.Message === "Message Sended") {
                setVerif(true)
            }
        })
        
    }
    
    useEffect(() => {
        contactUsFunction();
    }, [])
  
    console.log(name)
    console.log(email)
    console.log(phone)
    console.log(message)

    return (
        <div className=" mt-16">
            <p className="text-3xl text-gray-500  flex justify-center  pb-8">CONTACT US</p>
            <p className=" text-xl text-black flex  justify-center">For any orders, drop us a line!</p>
            <div className="flex  justify-center">
                <input onChange={(e) => setName(e.target.value)} className="border border-gray-400 p-4  w-10/12 md:w-7/12 mt-10" type="text" placeholder="Name" />
            </div>
            <div className="flex  justify-center">
                <input onChange={(e) => setEmail(e.target.value)} className="border border-gray-400 p-4  w-10/12 md:w-7/12 mt-5" type="text" placeholder="Email" />
            </div>
            <div className="flex  justify-center">
                <input onChange={(e) => setPhone(e.target.value)} className="border border-gray-400 p-4  w-10/12 md:w-7/12 mt-5" type="number" placeholder="Phone Number" />
            </div>
            <div className="flex  justify-center">
                <textarea onChange={(e) => setMessage(e.target.value)} rows="5" className="border border-gray-400 p-4 w-10/12 md:w-7/12 mt-5 " placeholder="Message" />
            </div>
            <div className="flex  justify-center">
                <button onClick={() => contactUsFunction()} className="mt-5 py-4 px-6 border border-amber-600 text-amber-600 bg-white hover:text-black hover:bg-amber-600">- SEND -</button>
            </div>
            <br />
            <br />
            <div className="flex justify-center text-gray-400 text-sm sinFrans">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</div>
            <div className="flex justify-center text-black text-base sinFrans mt-5">Better yet, see us in person!</div>
            <div className="flex justify-center text-black text-base sinFrans mt-5">We love our customers, so feel free to contact us any time.</div>
            <div className="flex justify-center text-black text-base sinFrans mt-5">
                <a className="mt-5 py-4 px-6 border border-green-700 text-white bg-green-700 hover:text-black hover:bg-green-700" href="https://api.whatsapp.com/send/?phone=33772047880&text&app_absent=0" target="_blank">WHATSAPP</a>
            </div>
            <br />
            {/* {
                // response.Message === "Payment Operation Succeded"
                response === "Message Sended" ?
                    <Module1>
                        <div className="relative text-align-center ">
                            <img className="w-full h-6/12" src="https://img1.wsimg.com/isteam/stock/gpKN1pP/:/cr=t:6.15%25,l:0%25,w:100%25,h:65.23%25/rs=w:515,h:235,cg:true" alt="" />
                            <img onClick={() => setModule1(false)} className="w-6 cursor-pointer text-base absolute top-2 right-4  " src={close} alt="" />
                        </div>
                        <p className="flex justify-center text-2xl text-amber-600 mt-3">You Ordered Successfully</p>
                        <p className="flex justify-center text-base text-gray-400 mt-2">your order will arrive within 2 days maximum</p>
                    </Module1>
                    :
                    <div></div>
            } */}

            {
                verif ?
                    <Module1>
                        <div className="relative text-align-center ">
                            <img className="w-full h-6/12" src="https://img1.wsimg.com/isteam/stock/gpKN1pP/:/cr=t:6.15%25,l:0%25,w:100%25,h:65.23%25/rs=w:515,h:235,cg:true" alt="" />
                            <img onClick={() => setVerif(false)} className="w-6 cursor-pointer text-base absolute top-2 right-4  " src={close} alt="" />
                        </div>
                        <p className="flex justify-center text-2xl text-amber-600 mt-3">You Message Sent Successfully</p>
                        <p className="flex justify-center text-base text-gray-400 mt-2">We will reply you as soon as possible</p>
                    </Module1> :
                    <div></div>
            }
        </div>
    );
}

export default ContactUs;