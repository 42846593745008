import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";



function OrderSummary(props) {
    const [cartitems, setCartItems] = useState()
    useEffect(() => {
        getCart()
    }, [])


    async function getCart() {
        axios.get(`https://nmvapes.com/eCommerce/api/cart_read.php?session_id=${localStorage.getItem("session_id")}`, {
            headers: {
                // 'Content-Type': 'application/json',
                // 'Authorization': props.jwtUserId
            }
        })
            .then((res) => {
                setCartItems(res.data)
                console.log(res.data)
                console.log("IT iS CATCHED")
            })
            .catch((error) => {
                console.error(error)
            });
    }
    console.log("CartItems Lelemnet")
    console.log(cartitems)
    console.log(cartitems)
    console.log(props.jwtUserId)
    console.log(props.product)
    return (
        <div>
            <div>
                <div className="flex space-x-2 p-2">
                    <img className="w-36 h-36" src={props.product?.image} alt="" />
                    <div>
                        <div>{props.product?.product_name}</div>
                        <br />
                        <div className="text-green-500">Added To Cart</div>
                    </div>
                </div>
                <div className="flex space-x-5">
                    <Link to="/checkOut">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded">CHECKOUT</button>
                    </Link>
                    <button className="bg-white text-blue-500 hover:bg-blue-500 hover:text-white border p-3 border-blue-500 rounded">CONTINUE SHOPPING</button>
                </div>
                
                <br />
                <hr />
                <br />
                <div>
                    <h1>My cart</h1>
                    <div>
                        {cartitems?.Items.map((item) => (
                            <div key={item.product_id}>
                                <div  className="flex space-x-2 p-2">
                                    <img className="w-36 h-36" src={item.product_image} alt="" />
                                    <div>
                                        <p className="text-gray-400">{item.product_name}</p>
                                        <br />
                                        <div className="flex space-x-1">
                                            <p>{item.P_Now} €</p>
                                            <div className="flex ">
                                                <div>&#40;</div>
                                                <div className="flex space-x-1">
                                                    <div>{item.quantity}</div>
                                                    <div>{item.quantity === "1" ? <div>item</div> : <div>items</div>}</div>
                                                </div>
                                                <div>&#41;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))}
                    </div>
                </div>
                <br/>
                <div className="mb-16">
                    <Link to="/cart">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white p-3 rounded">VIEW YOUR CART</button>
                    </Link>
                </div>
            </div>

        </div>
    );
}

export default OrderSummary;


