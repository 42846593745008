import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import cartShop from "../assets/cartShop.png";
import waste from "../assets/waste.png";

function Cart(props) {
  const [cartitems, setCartItems] = useState();
  const [empty, setEmpty] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState();
  const [itemProd, setItem] = useState("");
  const [ref, setRef] = useState(0);
  useEffect(() => {
    getCart();
  }, []);

  // if (window.performance) {
  //     if (performance.navigation.type == 1) {
  //       alert( "This page is reloaded" );
  //     }
  //     else {
  //       alert( "This page is not reloaded");
  //     }
  //   }
  async function getCart() {
    axios
      .get(
        `https://nmvapes.com/eCommerce/api/cart_read.php?session_id=${localStorage.getItem(
          "session_id"
        )}`,
        {
          headers: {
            // 'Content-Type': 'application/json',
            // 'Authorization': props.jwtUserId
          },
        }
      )
      .then((res) => {
        setCartItems(res.data);
        console.log("cartitems");
        console.log(res.data);
        // refresh()
        props.getCartTotal();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  console.log("props.cartTotal");
  console.log(props.cartTotal);

  useEffect(() => {
    remove();
  }, []);

  const refresh = () => {
    window.location.reload();
  };
  // console.log("itemProd")
  // console.log(itemProd)
  function remove(e) {
    // cartitems?.Items.map((item) => {
    // setItem(e)
    console.log("e.target.value");
    console.log(e);
    axios
      .delete(
        `https://nmvapes.com/eCommerce/api/cart_delete.php?session_id=${localStorage.getItem(
          "session_id"
        )}&product_id=${e}`,
        {
          headers: {},
        }
      )
      .then((res) => {
        getCart();

        //     setDeleteResponse(res.data);
        // if (res.data.Message === "Item Deleted From The Cart") {
        //     window.location.reload();
        // }
        // })
        // .catch((error) => {
        //     console.error(error)
      });
    // })
  }

  return (
    <div>
      {cartitems?.Message === "Your Cart Is Empty" ? (
        <div className="p-10">
          <div className="h-screen flex items-center justify-center">
            <div className="flex-row justify-center items-center">
              <div className=" text-center ">
                <div>
                  <img
                    className=" w-11 block ml-auto mr-auto "
                    src={cartShop}
                    alt=""
                  />
                </div>
                <br />
                <div>
                  <h1 className=" text-2xl">Your shopping cart is empty</h1>
                </div>
                <br />
                <div>
                  <p className="">What are you waiting for!</p>
                </div>
                <br />
                <Link to="/">
                  <input
                    type="button"
                    value="START SHOPPING"
                    className=" w-64 h-12 bg-blue-600 text-white rounded-md border-2 border-blue-500 cursor-pointer opacity-90 hover:opacity-100"
                  ></input>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-10">
          <div className="flex space-x-1 text-2xl ">
            <h1>My cart</h1>
            <div className="flex ">
              <div>&#40;</div>
              <div className="flex space-x-1">
                <div>{props.cartTotal?.Items_Count}</div>
                <div>items</div>
              </div>
              <div>&#41;</div>
            </div>
          </div>
          <br />
          <br />

          <div className="block md:flex md:justify-between">
            <div>
              {cartitems?.Items.map((item) => {
                return (
                  <div
                    key={item.product_id}
                    className="flex justify-between mb-10"
                  >
                    <div className="flex space-x-8">
                      <img
                        className="w-44 h-44"
                        src={item.product_image}
                        alt=""
                      />
                      <div>
                        <div className="text-gray-500 text-base">
                          {item.model_nb}
                        </div>
                        <div className="sm:text-sm md:text-base">
                          <strong>{item?.product_name}</strong>
                        </div>
                        <div className="flex space-x-2">
                          <div className="text-green-500 mt-2">quantity:</div>
                          <div className="text-green-500 mt-2">
                            {item?.quantity}
                          </div>
                        </div>
                        <br />

                        <div
                          onClick={(e) => remove(item.product_id)}
                          className="text-gray-500 flex space-x-2 cursor-pointer"
                        >
                          <div>Remove</div>
                          <img className="w-5 h-5 " src={waste} alt="" />
                        </div>
                      </div>
                      <div className="pl-3">
                        <br />
                        <div>
                          <strong> {item?.P_Now * item?.quantity}€</strong>
                        </div>
                        {/* <br />
                                                <div className="flex">
                                                    <p className="cursor-pointer text-xl border border-gray-500 rounded-l p-2">+</p>
                                                    <input className="border border-gray-500  p-2 w-10" type="number" />
                                                    <p className="cursor-pointer text-xl border border-gray-500 rounded-r p-2">-</p>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-between">
              <div></div>
              <div className="mt-5 md:mt-0 block w-80 border rounded-2xl border-gray-300 h-80 p-5">
                <div className="text-lg text-black">Order Summary</div>
                <br />
                <div className="space-x-2 text-gray-300 flex">
                  <div>Subtotal</div>
                  <div className="flex ">
                    <div>&#40;</div>
                    <div className="flex space-x-1">
                      <div>{props.cartTotal?.Items_Count}</div>
                      <div>items</div>
                    </div>
                    <div>&#41;</div>
                  </div>
                </div>
                <br />
                <div className="text-lg text-black">Order Totals</div>
                <br />
                <div>
                  <div className="flex justify-between">
                    <div>Total</div>
                    <div>{props.cartTotal?.Total_Amount} €</div>
                  </div>
                </div>
                <br />
                <div>
                  <Link to="/checkout">
                    <button
                      className="bg-blue-600 p-3 border rounded text-white w-full"
                      type="button"
                    >
                      CHECKOUT NOW
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cart;
