import React from "react";
import { Link } from "react-router-dom";
import inst from "../assets/inst.png";
import close from "../assets/close.png";
import instagramii from "../assets/instagramii.png";

function Footer(props) {
    return (

        <div className="bg-black w-full ">
            <p className="text-sm text-gray-300 sinFrans flex justify-center pt-10 pb-5">COPYRIGHT © 2022 NM VAPES - ALL RIGHTS RESERVED.</p>
            <Link to={"/contact-us"}><p className="text-sm cursor-pointer text-gray-300 sinFrans flex justify-center hover:text-amber-600 pb-5">CONTACT US </p></Link>
            <div className="flex justify-start pb-5">
                <a target="blank" href="https://instagram.com/nmvapes.fr?igshid=YmMyMTA2M2Y="><img className=" w-20 pl-10" src={instagramii} alt="" /></a>
            </div>
        </div>

    );
}

export default Footer;