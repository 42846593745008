import React, { useState, useEffect } from "react";
import express from "../../assets/express.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Carousel from "react-grid-carousel";

function ProductCard(props) {
  // const scrollRef = useRef()
  var settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
  };
  const [widgets, setWidgets] = useState();
  useEffect(() => {
    getWidgets();
  }, []);

  async function getWidgets() {
    axios
      .get(
        "https://nmvapes.com/eCommerce/api/products_read.php",
        {
          view: "web_desktop",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setWidgets(response.data);
        console.log("response.data");
        console.log(response.data);
      });
  }
  console.log("widgets");
  console.log(widgets);
  return (
    <div>
      {/* <div id="700"></div>
      <br />
      <br />
      <br /> */}
      {/* <div className=" text-gray-500 text-3xl border-l-4 border-amber-600 pl-4">
        <strong>700 PUFF</strong>
      </div> */}
      {/* <div className="grid grid-cols-2  md:grid-cols-4 gap-8 mt-0 ">
        {widgets?.map((item) => {
          return (
            <div>
              {item?.puff === 700 ? (
                <Link key={item.product_id} to={`/product/${item.product_id}`}>
                  <div className="cursor-pointer border border-gray-200 rounded-xl shadow-lg hover:shadow-md hover:shadow-gray-500">
                    <img
                      src={item.l_image}
                      className="mb-4  rounded-xl"
                      alt=""
                    />
                    <p className="text-base md:text-xl text-gray-500 pl-2 ">
                      {item.product_name}
                    </p>
                    <div>
                      <p className=" text-base md:text-xl text-black pl-2  ">
                        <strong>{item.P_Now} €</strong>
                      </p>
                      <div className="flex pl-2 space-x-2">
                        <p className=" text-base md:text-xl text-black  ">
                          {item.puff}
                        </p>
                        <p className=" text-base md:text-xl text-black  ">
                          <strong>PUFF</strong>
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        className="w-20 h-5 ml-2 mb-2 mt-1"
                        src={express}
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div> */}
      {/* <div id="1800"></div>
      <br />
      <br />
      <br /> */}
      
      {/* <div id="2500"></div>
      <br />
      <br />
      <br /> */}
      <div className=" text-gray-500 text-3xl border-l-4 border-amber-600 pl-4">
        <strong>2500 PUFF</strong>
      </div>
      <div className="mt-5 grid grid-cols-2  md:grid-cols-4 gap-8">
        {widgets?.map((item) => {
          return (
            <div>
              {item?.puff === 2500 ? (
                <Link key={item.product_id} to={`/product/${item.product_id}`}>
                  {/* {item?.model_nb === "NM-2500" ? ( */}
                    <div className="cursor-pointer border border-gray-200 rounded-xl shadow-lg hover:shadow-md hover:shadow-gray-500">
                      <img
                        src={item.l_image}
                        className="mb-4  rounded-xl"
                        alt=""
                      />
                      <p className="text-base md:text-xl text-gray-500 pl-2 ">
                        {item.product_name}
                      </p>
                      <div>
                        <p className=" text-base md:text-xl text-black pl-2  ">
                          <strong>{item.P_Now} €</strong>
                        </p>

                        <div className="flex pl-2 space-x-2">
                          <p className=" text-base md:text-xl text-black  ">
                            {item.puff}
                          </p>
                          <p className=" text-base md:text-xl text-black  ">
                            <strong>PUFF</strong>
                          </p>
                        </div>
                      </div>
                      <div>
                        <img
                          className="w-20 h-5 ml-2 mb-2 mt-1"
                          src={express}
                          alt=""
                        />
                      </div>
                    </div>
                  {/* ) : (
                    <div className="invisible cursor-pointer border border-gray-200 rounded-xl shadow-lg hover:shadow-md hover:shadow-gray-500">
                      <img
                        src={item.l_image}
                        className="mb-4  rounded-xl"
                        alt=""
                      />
                      <p className="text-base md:text-xl text-gray-500 pl-2 ">
                        {item.product_name}
                      </p>
                      <div>
                        <p className=" text-base md:text-xl text-black pl-2  ">
                          <strong>{item.P_Now} €</strong>
                        </p>

                        <div className="flex pl-2 space-x-2">
                          <p className=" text-base md:text-xl text-black  ">
                            {item.puff}
                          </p>
                          <p className=" text-base md:text-xl text-black  ">
                            <strong>PUFF</strong>
                          </p>
                        </div>
                      </div>
                      <div>
                        <img
                          className="w-20 h-5 ml-2 mb-2 mt-1"
                          src={express}
                          alt=""
                        />
                      </div>
                    </div>
                  )} */}
                </Link>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
      <div id="5000"></div>
      {/* <br />
      <br />
      <br /> */}
      <div className=" text-gray-500 text-3xl border-l-4 border-amber-600 pl-4 mt-10">
        <strong>5000 PUFF</strong>
      </div>
      <div className="mt-5 grid grid-cols-2  md:grid-cols-4 gap-8">
        {widgets?.map((item) => {
          return (
            <div>
              {item?.puff === 5000 ? (
                <Link key={item.product_id} to={`/product/${item.product_id}`}>
                  {/* {item?.model_nb === "NM-5000" ? ( */}
                    <div className="cursor-pointer border border-gray-200 rounded-xl shadow-lg hover:shadow-md hover:shadow-gray-500">
                      <img
                        src={item.l_image}
                        className="mb-4  rounded-xl"
                        alt=""
                      />
                      <p className="text-base md:text-xl text-gray-500 pl-2 ">
                        {item.product_name}
                      </p>
                      <div>
                        <p className=" text-base md:text-xl text-black pl-2  ">
                          <strong>{item.P_Now} €</strong>
                        </p>

                        <div className="flex pl-2 space-x-2">
                          <p className=" text-base md:text-xl text-black  ">
                            {item.puff}
                          </p>
                          <p className=" text-base md:text-xl text-black  ">
                            <strong>PUFF</strong>
                          </p>
                        </div>
                      </div>
                      <div>
                        <img
                          className="w-20 h-5 ml-2 mb-2 mt-1"
                          src={express}
                          alt=""
                        />
                      </div>
                    </div>
                  {/* ) : (
                    <div className="invisible cursor-pointer border border-gray-200 rounded-xl shadow-lg hover:shadow-md hover:shadow-gray-500">
                      <img
                        src={item.l_image}
                        className="mb-4  rounded-xl"
                        alt=""
                      />
                      <p className="text-base md:text-xl text-gray-500 pl-2 ">
                        {item.product_name}
                      </p>
                      <div>
                        <p className=" text-base md:text-xl text-black pl-2  ">
                          <strong>{item.P_Now} €</strong>
                        </p>

                        <div className="flex pl-2 space-x-2">
                          <p className=" text-base md:text-xl text-black  ">
                            {item.puff}
                          </p>
                          <p className=" text-base md:text-xl text-black  ">
                            <strong>PUFF</strong>
                          </p>
                        </div>
                      </div>
                      <div>
                        <img
                          className="w-20 h-5 ml-2 mb-2 mt-1"
                          src={express}
                          alt=""
                        />
                      </div>
                    </div>
                  )} */}
                </Link>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProductCard;
