import React from "react";
import close from "../assets/close.png";


function Attention(props) {
    return (
        <div className="bg-black flex justify-between ">
            <div className="invisivle">x</div>
            <div className="">
                <p className="sinFrans logo flex justify-center"><strong>WARNING !!</strong></p>
                <p className="sinFrans logo flex justify-center px-5 text-xs md:text-base">This product contains NICOTINE. NICOTINE is an addictive chemical. Our Products are restricted to adults 21+ only!</p>
            </div>
            <div onClick={() => props.setAttention(false)} className="cursor-pointer pr-5 pt-3 w-16 md:w-10"><img src={close} alt="" /></div>
        </div>
    );
}

export default Attention;